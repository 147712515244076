@import "nlib/config";

.rpaDetailsPanel {
  svg {
    font-size: 1rem;
  }
  .sideBarHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: $uiWhiteColor;
    box-shadow: 0 0 2px 4px $uiWhiteColor;
    line-height: 1;
    > div {
      padding-bottom: 0;
    }
    .amount {
      font-size: 1rem;
    }
  }
  .info {
    display: flex;
    align-items: center;
  }
  .account {
    margin-top: $contentDefaultIndent;
  }
  .description {
    &.muted {
      color: $uiMediumDarkColor;;
    }
    svg {
      font-size: 1rem;
      &.warning {
        color: $uiWarningColor;
      }
    }
  }
  .title {
    margin-top: $contentDefaultIndent * 2;
    font-weight: 500;
  }
  .subTitle {
    font-weight: 500;
    + .description {
      margin-top: 0;
    }
  }
  .payee {
    .description {
      margin-top: 0;
    }
  }
  .aiDescription {
    .description {
      white-space: pre-wrap;
    }
  }
  .entity {
    margin-top: $contentDefaultIndent;
    + .entity {
      margin-top: $contentDefaultIndent * 2;
    }
    .value {
      display: flex;
      align-items: center;
      height: 2rem;
      padding: 0 $contentDefaultIndent;
      border-radius: $uiBorderRadius;
      background-color: $uiBackgroundColor;
      white-space: nowrap;
      .text {
        flex: 1;
        margin-right: $contentDefaultIndent;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      svg {
        margin-right: 0.3em;
        font-size: 1rem;
      }
      .confidence {
        margin-left: $contentDefaultIndent;
        > div {
          position: relative;
          left: 0;
          transform: none;
        }
      }
    }
    .title {
      margin-top: $contentDefaultIndent;
      font-size: 0.75rem;
      font-weight: normal;
    }
    .table {
      margin-top: $contentDefaultIndent / 2;
      border: 1px solid $uiBorderColor;
      border-radius: $uiBorderRadius;
      font-size: 0.75rem;
      line-height: 1;
    }
  }
  .noData {
    padding: 0;
  }
  .emptyText {
    margin-top: -16px;
    color: $uiMediumDarkColor;
    font-size: 1rem;
  }
}
